import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Entry } from 'contentful';
import { ContentfulService } from 'src/app/contentful.service';
import { ProductDialogComponent } from '../product-dialog/product-dialog.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() fields : {};

  private timer: any;
  public isLoading: boolean = true;
  products: Entry<any>[] = [];
  defaultImage = "assets/image.svg";

  constructor(
    private contentfulService: ContentfulService,
    public dialog: MatDialog
  ) { }

  async ngOnInit() {
    this.isLoading = true;
    this.products = await this.contentfulService.searchProducts('');
    this.isLoading = false;
    console.log('fields', this.fields);
  }

  searchProducts = async (event) => {
    clearTimeout(this.timer);
    this.isLoading = true;
    this.timer = setTimeout(async () => {
      const value = event.target.value;
      this.products = await this.contentfulService.searchProducts(value);
      this.isLoading = false;
    }, 400);
  }

  openDialog = (product) => {
    this.dialog.open(ProductDialogComponent, {
      data: product
    });
  }

}
