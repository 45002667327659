import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, AfterViewInit {
  @Input() fields;
  styles;

  constructor(
    private domSanitizer: DomSanitizer,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.styles = this.fields?.layout?.fields;
  }

  public safeUrl = (url) => {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url)
  }

  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
      return '<p>Error</p>';
    }
    return documentToHtmlString(richText);
  }

  ngAfterViewInit() {
    this.ref.detach()
  }
}
