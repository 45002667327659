<div class="container"
  *ngIf="fields"
>
  <div *ngFor="let component of fields.components; let index = index">
    <app-slider *ngIf="component.sys.contentType.sys.id === 'slider'" [fields]="component.fields"></app-slider>
    <app-banner *ngIf="component.sys.contentType.sys.id === 'banner'" [fields]="component.fields"></app-banner>
    <app-category-grid *ngIf="component.sys.contentType.sys.id === 'categoryGrid'" [fields]="component.fields"></app-category-grid>
    <app-products *ngIf="component.sys.contentType.sys.id === 'productList'" [fields]="component.fields"></app-products>
    <app-search *ngIf="component.sys.contentType.sys.id === 'search'" [fields]="component.fields"></app-search>
    <app-carousel *ngIf="component.sys.contentType.sys.id === 'carousel'" [fields]="component.fields"></app-carousel>
    <app-address *ngIf="component.sys.contentType.sys.id === 'address'" [fields]="component.fields"></app-address>
    <app-product-list *ngIf="component.sys.contentType.sys.id === 'productList'" [fields]="component.fields"></app-product-list>
  </div>
</div>