import { Component, OnInit, Inject, Self } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss']
})
export class ProductDialogComponent implements OnInit {
  defaultImage = "assets/image.svg";

  constructor(
    public dialogRef: MatDialogRef<Self>,
    @Inject(MAT_DIALOG_DATA) public data) {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
