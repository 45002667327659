<mat-card
  *ngIf="fields"
  [ngStyle]="{
    'margin': styles?.margin,
    'padding': styles?.padding,
    'background-color': styles?.backgroundColor,
    'border-radius': (styles?.borderRadius?.toString() || '0') + 'px',
    'color': styles?.fontColor,
    'text-align': styles?.textAlignment
  }"
>
  <mat-card-header>
    <mat-card-title>{{ fields.title }}</mat-card-title>
  </mat-card-header>
  <mat-grid-list
    [cols]="styles?.columnSize || 2"
    rowHeight="1:1"
    [gutterSize]="(styles?.gutterSize?.toString() || '4') + 'px'"
  >
    <mat-grid-tile
      *ngFor="let category of fields.categories; let index = index"
      [routerLink]="['/category', category.fields.slug]"
      matRipple
      [ngStyle]="{
        'border-radius': (category?.fields?.layout?.fields?.borderRadius?.toString() || '0') + 'px'
      }"
    >
      <img [defaultImage]="defaultImage" [lazyLoad]="category.fields.image.fields.file.url" [alt]="category.fields.title" />
      <mat-grid-tile-footer
        [ngStyle]="{
          'color': category?.fields?.layout?.fields?.fontColor,
          'text-align': category?.fields?.layout?.fields?.textAlignment,
          'background-color': category?.fields?.layout?.fields?.backgroundColor
        }"
      >
        {{ category.fields.title }}
      </mat-grid-tile-footer>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-card-actions *ngIf="fields.button">
    <a
      mat-stroked-button
      [routerLink]="[fields.button.fields.slug]"
      [ngStyle]="{
        'background-color': fields.button.fields.backgroundColor,
        'color': fields.button.fields.fontColor
      }"
    >{{ fields.button.fields.title }}</a>
  </mat-card-actions>
</mat-card>