import { Component, OnInit, Input } from '@angular/core';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-category-grid',
  templateUrl: './category-grid.component.html',
  styleUrls: ['./category-grid.component.scss']
})
export class CategoryGridComponent implements OnInit {
  @Input() fields;
  defaultImage;
  styles;

  constructor(
    public globals: Globals
  ) { }

  ngOnInit(): void {
    this.defaultImage = this.globals.defaultImage;
    this.styles = this.fields?.layout?.fields;
  }

}
