import { Component, OnInit, Input } from '@angular/core';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() fields;
  defaultImage;

  styles;

  constructor(
    public globals: Globals
  ) { }

  async ngOnInit() {
    this.defaultImage = this.globals.defaultImage;
    this.styles = this.fields?.layout?.fields;
  }
}
