<div
  class="product-list-container"
>
  <mat-card
    *ngIf="fields && fields?.bannerLayout && activeCategory"
    class="banner"
    [ngStyle]="{
      'border-radius':
        (fields?.bannerLayout?.fields?.borderRadius?.toString() || '0') + 'px',
      padding: fields?.bannerLayout?.fields?.padding,
      margin: fields?.bannerLayout?.fields?.margin
    }"
  >
    <mat-card-header
      [ngStyle]="{
        'align-items': fields?.bannerLayout?.fields?.textHorizontalAlignment,
        color: fields?.bannerLayout?.fields?.fontColor,
        'justify-content': fields?.bannerLayout?.fields?.textVerticalAlignment
      }"
    >
      <mat-card-title>{{ activeCategory.fields.title }}</mat-card-title>
    </mat-card-header>
    <div class="image-wrapper" *ngIf="activeCategory?.fields?.image?.fields">
      <img
        [defaultImage]="defaultImage"
        [lazyLoad]="activeCategory?.fields.image.fields.file.url"
        [alt]="activeCategory?.fields.title"
      />
      <div
        class="overlay"
        [ngStyle]="{
          'background-color': fields?.bannerLayout?.fields?.backgroundColor
        }"
      ></div>
    </div>
  </mat-card>

  <mat-card class="search">
    <div class="search-box">
      <input
        type="text"
        name="search"
        class="search-field"
        placeholder="Arama..."
        (keyup)="searchProducts($event)"
      />
      <button
        mat-icon-button
        aria-label="Search"
        class="search-button"
      >
        <mat-icon svgIcon="custom_search"></mat-icon>
    </button>
    </div>
  </mat-card>

  <div
    class="grid-container"
    *ngIf="fields && categories"
    [ngStyle]="{
      'border-radius':
        (fields?.layout?.fields?.borderRadius?.toString() || '0') + 'px',
      padding: fields?.layout?.fields?.padding,
      margin: fields?.layout?.fields?.margin,
      background: fields?.layout?.fields?.backgroundColor
    }"
  >
    <div class="categories-grid-item">
      <mat-selection-list role="navigation" [multiple]="false">
        <mat-list-option
          *ngFor="let category of categories; let index = index"
          [value]="category.fields.slug"
          [selected]="activeCategory.fields.slug == category.fields.slug"
          (click)="setActiveCategory(category)"
          [ngStyle]="activeCategory.fields.slug == category.fields.slug ? getCategoryFlatActiveStyle(category) : getCategoryFlatStyle(category)"
        >
          {{ category.fields.title }}
        </mat-list-option>
      </mat-selection-list>
    </div>
    <div class="products-grid-item">
      <mat-list>
        <mat-list-item
          *ngFor="
            let product of activeCategory.fields.products;
            let index = index
          "
          (click)="setSelectedProduct(product.sys.id)"
          [ngClass]="{ active: selectedProduct === product.sys.id }"
          [ngStyle]="{
            'background-color': product?.fields?.layout?.fields?.backgroundColor,
            color: product?.fields?.layout?.fields?.fontColor,
            padding: product?.fields?.layout?.fields?.padding,
            margin: product?.fields?.layout?.fields?.margin,
            'border-radius': (product?.fields?.layout?.fields?.borderRadius?.toString()  || '0') + 'px',
            'text-align': product?.fields?.layout?.fields?.textAlignment
          }"
        >
          <img
            [defaultImage]="defaultImage"
            [lazyLoad]="product.fields.image.fields.file.url"
            [alt]="product.fields.title"
          />
          <div mat-line class="title">{{ product.fields.title }}</div>
          <div mat-line class="description">
            {{ product.fields.description }}
          </div>
          <div mat-line class="price" *ngIf="product?.fields?.price">
            {{ product.fields.price }} ₺
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>
