<mat-card
  *ngIf="fields"
  [ngStyle]="{
    'margin': styles?.margin,
    'padding': styles?.padding,
    'background-color': styles?.backgroundColor,
    'border-radius': (styles?.borderRadius?.toString() || '0') + 'px'
  }"
>
  <mat-card-header>
    <mat-card-title
      [ngStyle]="{
        'color': styles?.titleFontColor,
        'text-align': styles?.titleTextAlignment
      }"
    >
      {{ fields.title }}
    </mat-card-title>
    <mat-card-subtitle
      [innerHtml]="_returnHtmlFromRichText(fields.addressDetails)"
      [ngStyle]="{
        'color': styles?.detailsFontColor,
        'text-align': styles?.detailsTextAlignment
      }"
      ></mat-card-subtitle>
  </mat-card-header>
  <iframe
    [src]="safeUrl('https://maps.google.com/maps?q='+fields.location.lat+','+fields.location.lon+'&hl=tr&z=14&amp;output=embed')"
    width="100%"
    height="300"
    frameborder="0"
    loading="lazy"
    title="map"
  ></iframe>
</mat-card>