import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";
import { Globals } from './globals'
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { ContentfulService } from './contentful.service';
import { BannerComponent } from './components/banner/banner.component';
import { CategoryGridComponent } from './components/category-grid/category-grid.component';
import { AddressComponent } from './components/address/address.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { SearchComponent } from './components/search/search.component';
import { ProductDialogComponent } from './components/product-dialog/product-dialog.component';
import { CarouselComponent } from './components/carousel/carousel.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    BannerComponent,
    CategoryGridComponent,
    AddressComponent,
    ProductListComponent,
    SearchComponent,
    ProductDialogComponent,
    CarouselComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LazyLoadImageModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatGridListModule,
    MatButtonModule,
    MatListModule,
    MatDialogModule,
    MatChipsModule,
    CarouselModule
  ],
  providers: [
    ContentfulService,
    Globals
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
