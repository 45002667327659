import { Component } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'restaurant-angular';
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      "custom_dial-pad",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/dial-pad.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "custom_search",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/search.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "custom_food",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/food.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "custom_home",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/home.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "custom_back",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/back.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "custom_close",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/close.svg")
    );
  }
}
