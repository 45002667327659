import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/globals';
import {cloneDeep} from 'lodash';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
  @Input() fields;

  defaultImage;
  selectedProduct = -1;
  categories;
  activeCategory;

  private timer: any;

  constructor(
    private route: ActivatedRoute,
    public globals: Globals
  ) { }

  async ngOnInit() {
    this.defaultImage = this.globals.defaultImage;
    this.selectedProduct = -1;

    this.route.params.subscribe(async (params) => {
      const cid = params['cid'] ? params['cid'] : '';
      this.categories = cloneDeep(this.fields.categories);
      this.activeCategory = cloneDeep(this.categories.find(category => category.fields.slug === cid));
    });
  }


  searchProducts = (event) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      this.route.params.subscribe(async (params) => {
        const cid = params['cid'] ? params['cid'] : '';
        this.categories = cloneDeep(this.fields.categories);
        this.activeCategory = cloneDeep(this.categories.find(category => category.fields.slug === cid));
      });
      if(event?.target?.value?.length > 0) {
        const categories = this.categories?.filter(category => {
          const products = category?.fields?.products?.filter(product => product?.fields?.title?.toLowerCase()?.includes(event?.target?.value?.toLowerCase()));
          if(products.length > 0) {
            category.fields.products = products;
            return category;
          };
        });
        if(categories?.length > 0) {
          this.activeCategory = categories[0];
          this.categories = categories;
        }else{
          this.route.params.subscribe(async (params) => {
            const cid = params['cid'] ? params['cid'] : '';
            this.categories = [];
            this.activeCategory = cloneDeep(this.fields.categories.find(category => category.fields.slug === cid));
            this.activeCategory.fields.products = [];
          });
        }
      }
    }, 200);
  }

  setActiveCategory = (category) => {
    this.activeCategory = category;
  }

  setSelectedProduct = (productId) => {
    this.selectedProduct = this.selectedProduct === productId ? -1 : productId;
  }

  getCategoryFlatActiveStyle = (category) => {
    return {
      'background-color': category.fields?.activeFlatLayout?.fields?.backgroundColor || category?.fields?.flatLayout?.fields?.backgroundColor,
      'color': category?.fields?.activeFlatLayout?.fields?.fontColor || category?.fields?.flatLayout?.fields?.fontColor,
      'padding': category?.fields?.activeFlatLayout?.fields?.padding || category?.fields?.flatLayout?.fields?.padding,
      'margin': category?.fields?.activeFlatLayout?.fields?.margin || category?.fields?.flatLayout?.fields?.margin,
      'border-radius': ((category?.fields?.activeFlatLayout?.fields?.borderRadius || category?.fields?.flatLayout?.fields?.borderRadius).toString()  || '0') + 'px',
      'text-align': category?.fields?.activeFlatLayout?.fields?.textAlignment || category?.fields?.flatLayout?.fields?.textAlignment
    }
  }

  getCategoryFlatStyle = (category) => {
    return {
      'background-color': category?.fields?.flatLayout?.fields?.backgroundColor,
      'color': category?.fields?.flatLayout?.fields?.fontColor,
      'padding': category?.fields?.flatLayout?.fields?.padding,
      'margin': category?.fields?.flatLayout?.fields?.margin,
      'border-radius': (category?.fields?.flatLayout?.fields?.borderRadius?.toString()  || '0') + 'px',
      'text-align': category?.fields?.flatLayout?.fields?.textAlignment
    }
  }
}
