<mat-card 
  class="carousel"
  *ngIf="fields"
  [ngStyle]="{
    'border-radius': (styles?.borderRadius?.toString() || '0') + 'px',
    'padding': styles?.padding,
    'margin': styles?.margin,
    'background-color': styles?.backgroundColor
  }"
>
  <mat-card-header
  [ngStyle]="{
    'text-align': styles?.textAlignment,
    'color': styles?.fontColor
  }"
  >
    <mat-card-title>{{ fields.title }}</mat-card-title>
    <mat-card-subtitle *ngIf="fields?.subtitle"> {{ fields.subtitle }} </mat-card-subtitle>
  </mat-card-header>
  <owl-carousel-o *ngIf="fields?.carouselItems" [options]="customOptions">
    <ng-container *ngFor="let carouselItem of fields.carouselItems; let index = index">
      <ng-template carouselSlide [id]="carouselItem.sys.id">
        <img [src]="carouselItem.fields.image.fields.file.url">
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</mat-card>