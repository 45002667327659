<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{ data.fields.title }}
    </mat-card-title>
    <button
      mat-icon-button
      aria-label="Kapat"
      class="close-button"
      (click)="closeDialog()"
    >
      <mat-icon svgIcon="custom_close"></mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <img [defaultImage]="defaultImage" [lazyLoad]="data.fields.image.fields.file.url" />
  </mat-card-content>
  <mat-card-footer *ngIf="data.fields.description">
    {{ data.fields.description }}
  </mat-card-footer>
</mat-card>
