<mat-card 
  *ngIf="fields"
  [ngStyle]="{
    'border-radius': (styles?.borderRadius?.toString() || '0') + 'px',
    'padding': styles?.padding,
    'margin': styles?.margin
  }"
>
  <mat-card-header
  [ngStyle]="{
    'align-items': styles?.textHorizontalAlignment,
    'color': styles?.fontColor,
    'justify-content': styles?.textVerticalAlignment
  }"
  >
    <mat-card-title>{{ fields.title }}</mat-card-title>
    <mat-card-subtitle> {{ fields.subtitle }} </mat-card-subtitle>
  </mat-card-header>
  <div class="image-wrapper" *ngIf="fields?.image?.fields">
    <img [defaultImage]="defaultImage" [lazyLoad]="fields.image.fields.file.url" [alt]="fields.title">
    <div class="overlay"
    [ngStyle]="{
      'background-color': styles?.backgroundColor
    }"
    ></div>
  </div>
</mat-card>