
<div class="container">
  <mat-toolbar class="toolbar">
    <a
      mat-icon-button
      aria-label="Home"
      class="toolbar-item"
      routerLink="/"
      [routerLinkActive]="['active']"
      [routerLinkActiveOptions]="{exact:true}"
    >
      <mat-icon svgIcon="custom_home"></mat-icon>
      <span>Ana Sayfa</span>
    </a>
    <a
      mat-icon-button
      aria-label="Search"
      class="toolbar-item"
      routerLink="/search"
      [routerLinkActive]="['active']"
      [routerLinkActiveOptions]="{exact:true}"
    >
      <mat-icon svgIcon="custom_search"></mat-icon>
      <span>Arama</span>
    </a>
    <a
      mat-icon-button
      aria-label="Kategoriler"
      class="toolbar-item"
      routerLink="/categories"
      [routerLinkActive]="['active']"
    >
      <mat-icon svgIcon="custom_food"></mat-icon>
      <span>Kategoriler</span>
    </a>
    <a
      mat-icon-button
      aria-label="İletişim"
      class="toolbar-item"
      routerLink="/contact-us"
      [routerLinkActive]="['active']"
      [routerLinkActiveOptions]="{exact:true}"
    >
      <mat-icon svgIcon="custom_dial-pad"></mat-icon>
      <span>İletişim</span>
    </a>
  </mat-toolbar>
  <router-outlet></router-outlet>
</div>
