<mat-card class="search">
  <div class="search-box">
    <input
      type="text"
      name="search"
      class="search-field"
      placeholder="Arama..."
      (keyup)="searchProducts($event)"
    />
    <a
      mat-icon-button
      aria-label="Search"
      class="search-button"
      routerLink="/search"
      [routerLinkActive]="['active']"
      [routerLinkActiveOptions]="{exact:true}"
    >
      <mat-icon svgIcon="custom_search"></mat-icon>
    </a>
  </div>
</mat-card>

<mat-card
  class="products"
  *ngIf="fields && !isLoading && products && products.length > 0"
  [ngStyle]="{
    'background-color': fields?.layout?.fields?.backgroundColor,
    color: fields?.layout?.fields?.fontColor,
    margin: fields?.layout?.fields?.margin,
    padding: fields?.layout?.fields?.padding,
    'text-align': fields?.layout?.fields?.textAlignment,
    'border-radius': (fields?.layout?.fields?.borderRadius?.toString()  || '0') + 'px'
  }"
>
  <mat-card-header>
    <mat-card-title>Arama sonuçları</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-grid-list [cols]="fields?.layout?.fields?.columnSize" rowHeight="1:1" [gutterSize]="(fields?.layout?.fields?.gutterSize.toString() || '4') + 'px'">
      <mat-grid-tile
        *ngFor="let product of products; let index = index"
        (click)="openDialog(product)"
        matRipple
        [ngStyle]="{
          'border-radius': (product?.fields?.searchItemLayout?.fields?.borderRadius?.toString() || '0') + 'px'
        }"
      >
        <mat-chip-list class="example-chip" *ngIf="product.fields.price">
          <mat-chip class="example-box">{{ product.fields.price }} ₺</mat-chip>
        </mat-chip-list>
        <img [defaultImage]="defaultImage" [lazyLoad]="product.fields.image.fields.file.url" [alt]="product.fields.title" />
        <mat-grid-tile-footer
          [ngStyle]="{
            'background-color': product?.fields?.searchItemLayout?.fields?.backgroundColor,
            color: product?.fields?.searchItemLayout?.fields?.fontColor,
            'text-align': product?.fields?.searchItemLayout?.fields?.textAlignment
          }"
        >
          <div class="mat-grid-list-text">
            {{ product.fields.title }}
            <span *ngIf="product.fields.description">{{ product.fields.description }}</span>

          </div>
        </mat-grid-tile-footer>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>

<mat-card class="not-found" *ngIf="!isLoading && products && products.length == 0">
  <mat-card-header>
    <mat-card-title>Üzgünüz...</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mart-card-text>Aramanızla ilgili uygun sonuç bulamadık.</mart-card-text>
  </mat-card-content>
</mat-card>

<mat-card class="loading" *ngIf="isLoading">
  <mat-card-header>
    <mat-card-title>Yükleniyor...</mat-card-title>
  </mat-card-header>
</mat-card>