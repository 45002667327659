import { Component, Input, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  @Input() fields;
  defaultImage;
  styles;

  customOptions: OwlOptions = {
    loop: true,
    slideTransition: 'linear',
    center: true,
    items: 2,
    nav: false,
  }


  constructor(
    public globals: Globals
  ) { }

  ngOnInit(): void {
    console.log('fields', this.fields);
    this.defaultImage = this.globals.defaultImage;
    this.styles = this.fields?.layout?.fields;

    // carousel settings
    this.customOptions.autoplay = this.fields?.settings?.fields?.autoPlay ||false;
    this.customOptions.mouseDrag = this.fields?.settings?.fields?.mouseDrag || false;
    this.customOptions.touchDrag = this.fields?.settings?.fields?.touchDrag || false;
    this.customOptions.dots = this.fields?.settings?.fields?.dots || false;
    this.customOptions.autoplaySpeed = this.fields?.settings?.fields?.autoPlaySpeed || 400;
    this.customOptions.smartSpeed = this.fields?.settings?.fields?.smartSpeed || 400;
    this.customOptions.autoplayTimeout = this.fields?.settings?.fields?.autoPlayTimeout || 5000;
    this.customOptions.margin = this.fields?.settings?.fields?.itemMargin || 0;

    console.log('customOptions', this.customOptions);
  }

}
