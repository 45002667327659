import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentfulService } from 'src/app/contentful.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  fields;

  constructor(
    private contentfulService: ContentfulService,
    private route: ActivatedRoute,
    private elementRef: ElementRef
  ) { }

  async ngOnInit() {
    this.route.params.subscribe(async (params) => {

      const slug = params['slug'] ? params['slug'] : 'home';
      const pageData = await this.contentfulService.getPage(slug);
      this.fields = pageData?.fields;

      console.log('fields', this.fields);

      const styles = this.fields?.layout?.fields;
      this.elementRef.nativeElement.ownerDocument.body.style = undefined;
      this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = styles?.backgroundColor;
      this.elementRef.nativeElement.ownerDocument.body.style.color = styles?.fontColor;
    });
  }
}
